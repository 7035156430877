//  CAUTION: ALWAYS put typed urls first.

export namespace Manifest {
    export namespace Url {
        export const base = `/Service`;

        export namespace Audit {
            export const base = `${Url.base}/Audit`;
            export const getActivity = `${base}/GetActivity`;
            export const getAudit = `${base}/GetAudit`;
            export const getFileFolderList = `${base}/GetFileFolderList`;
            export const getHubList = `${base}/GetHubList`;
            export const getUserList = `${base}/GetUserList`;
            export const getDiskUsage = `${base}/GetDiskUsage`;
            export const getTreeMap = `${base}/GetTreeMap`;
            export const getShare = `${base}/GetShare`;
            export const getCompanyHubsInfo = `${base}/GetCompanyHubsInfo`;
            export const updateShareSubscription = `${base}/UpdateShareSubscription`;
        }

        export namespace AdvancedSearch {
            export const base = `${Url.base}/Search`;
            const complete = `${base}/Complete`;

            export const initiate = `${base}`;
            export const completeBlog = `${complete}/Blog`;
            export const completeCalendar = `${complete}/Calendar`;
            export const completeFile = `${complete}/File`;
            export const completePeople = `${complete}/People`;
            export const completeProject = `${complete}/Project`;
            export const completeRoom = `${complete}/Room`;

        }

        export namespace Authentication {
            export const base = `${Url.base}/Authentication`;
            const twoFactorBase = `${Authentication.base}/TwoFactor`;

            export const adminSsoLogin = `${Url.base}/Admin/Sso/SignIn`;
            export const login = `${base}/Login`;
            export const logout = `${base}/Logout`;
            export const checkExternalUserRedirection = `${base}/External/Redirection`;
            export const confirmCredentials = `${base}/ConfirmCredentials`;
            export const revokeApiAuth = `${base}/Revoke/API`;
            export const accessToken = `${base}/AccessToken`;
            export const currentUserAccessToken = `${base}/AccessToken/CurrentUser`;
            export const sysAdminToken = `${base}/AccessToken/SysAdmin`;
            export const sysAdminTokenDelete = `${base}/AccessToken/SysAdmin/{0}`;
            export const accessTokenDelete = `${base}/AccessToken/{0}`;
            export const autoDisabling = `${base}/AutoDisabling`;

            export const forceUsers = `${twoFactorBase}/Force`;
            export const resetTwoFactor = `${twoFactorBase}/Reset/{0:guid}`;
            export const validateTwoFactor = `${twoFactorBase}/Validate`;
            export const getConfig = `${twoFactorBase}/{0}/Configure`;
            export const configure = `${twoFactorBase}/Configure`;

            export namespace SSO {
                export const login = `${Url.base}/Login/SSO`;

                export namespace SAML {
                    export const login = `${SSO.login}/SAML/{0:guid}`;
                    export const logout = `${SSO.login}/SAML/SLO/{0:guid}`;
                }

                export namespace OpenId {
                    export const login = `${SSO.login}/OpenId/SignIn/{0:guid}`;
                    export const callback = `${SSO.login}/OpenId/Callback/{0:guid}`;
                    export const logout = `${SSO.login}/OpenId/Logout`;
                }
            }

            export namespace Password {
                export const base = `${Authentication.base}/Password`;

                export const checkValidationToken = `${base}/Check/{0:guid}`
                export const initialize = `${base}/Initialize`;
                export const forgot = `${base}/Forgot`;
                export const reset = `${base}/Reset`;
                export const getCompanyNameFromToken = `${base}/Initialize/{0}/CompanyName`;
            }
        }

        export namespace Company {
            export const base = `${Url.base}/Company`;
            export const identified = `${base}/{0:guid}`;

            export const all = base;
            export const enabled = `${base}/Enabled`;
            export const disabled = `${base}/Disabled`;
            export const allLimited = `${base}/Limited`;
            export const getConfiguration = `${identified}/Configuration`;
            export const create = base;
            export const update = `${identified}`;
            export const updateTwoFactorForcedUser = `${identified}/2FA/ForcedUserKind`;
            export const status = `${identified}/Status`;
            export const enable = `${identified}/Enable`;
            export const disable = `${identified}/Disable`;
            export const extractResources = `${identified}/Extract/Resources`;
            export const extracting = `${base}/Extracting`;
            export const companyGroupsWithMembers = `${base}/Groups`;

            export namespace Settings {
                export namespace TwoFactor {
                    export const base = `${Company.base}/{0:guid}/2FA`;
                    export const getTwoFactorCompanySettings = `${TwoFactor.base}/Settings`;
                    export const getConnectedApiServices = `${TwoFactor.base}/ConnectedApiServices`;
                }

                export namespace SSO {
                    export const base = `${Company.base}/Sso`;
                    export const externalUserSsoAllowed = `${base}/Settings/ExternalAllowed`;
                    export const settings = `${base}/Settings/{0:guid}`;
                    export const deleteSettings = `${base}/Settings/{0:guid}/{1:guid}`;
                    export const updateUsers = `${base}/Settings/{0:guid}/Users`;
                    export const updateLogo = `${base}/Settings/{0:guid}/{1:guid}/Logo`;

                    export namespace OpenId {
                        export const base = `${SSO.base}/OpenId`;
                    }

                    export namespace SAML {
                        export const base = `${SSO.base}/Saml`;

                        export const certificate = `${base}/Certificate`;
                        export const downloadMetadata = `${base}/Metadata/{0:guid}`;
                        export const uploadMetadata = `${base}/Metadata`;
                        export const uploadMetadataText = `${base}/Metadata/Text`;
                    }
                }

                export namespace Shortcut {
                    const base = `${Company.base}/Shortcut`;

                    export const isAvailable = `${base}/Available`;
                }

                export namespace FederatedAuth {
                    const base = `${Company.identified}/FederatedAuth`;

                    export const configurations = `${base}/Configurations`;
                    export const updateConfiguration = `${base}/Configuration`;
                    export const deleteConfiguration = `${base}/Configuration/{1:guid}`;
                }
            }

            export namespace Eds {
                export const base = `${Company.identified}/Eds`;

                export const settings = `${base}/Settings`;
                export const configureHook = `${base}/Webhook`;
            }

            export namespace ResourceType {
                export const base = `${Company.identified}/ResourceType`;

                export const find = base;
                export const update = base;
                export const getfromResource = `${Company.base}/ResourceType/{0:guid}`;
            }

            export namespace ActiveDirectory {
                export const base = `${Company.identified}/Directory`;

                export const get = `${ActiveDirectory.base}`;
                export const getDetails = `${Company.identified}`;
                export const getLastSync = `${ActiveDirectory.base}/LastSync`
                export const update = `${ActiveDirectory.base}/ActiveDirectory`;
                export const sync = `${ActiveDirectory.base}/Sync`;
            }

            export namespace User {
                export const base = `${Company.identified}/User`;
                export const identified = `${base}/{1:guid}`;

                export const allUsers = `${Company.base}/User`;
                export const companyIdentities = `${Company.identified}/Identities`;
                export const companyUserIdentities = `${Company.identified}/Identities/User`;
                export const companyGroupIdentities = `${Company.identified}/Group/{1:guid}/Identities`;
                export const getIdentitiesWithNotValidated = `${Company.identified}/Identities/User/All`;
                export const identitiesFromRoom = `${Company.identified}/IdentitiesFromRoom/{1:guid}`;
                export const allRecipients = `${Company.base}/User/Recipient`;
                export const getAdminsEmail = `${Company.base}/User/AdminsEmail`;
                export const search = `${Company.base}/User/Search`;

                export const all = `${base}`;
                export const twoFactor = `${base}/TwoFactor`;
                export const creators = `${base}/Creators`;

                export const companyUser = `${identified}/CompanyUser`;
                export const enableInternal = `${identified}/Internal/Enable`;
                export const disableInternal = `${identified}/Internal/Disable`;
                export const enableExternal = `${identified}/External/Enable`;
                export const disableExternal = `${identified}/External/Disable`;
                export const deleteUsers = `${base}/Delete`;

                export const updateFirstAdmin = `${identified}/FirstAdmin`;

                export namespace Eds {
                    export const base = `${Company.User.identified}/Eds`;

                    export const settings = `${base}/Settings`;
                }
            }
        }

        export namespace EmailNotification {
            export const base = `${Url.base}/EmailNotification`;

            export const all = base;
            export const getSharedResources = `${base}/SharedResources`;
            export const root = `${base}/Root`;
            export const find = `${base}/{0}`;
            export const restoreInheritance = `${base}/RestoreInheritance`;
            export const update = base;

            export namespace Company {
                export const base = `${EmailNotification.base}/Company/{0}`;
            }
        }

        export namespace Login {
            export namespace Sso {
                export const getResult = `${Url.base}/Login/SSO/Result/{0:guid}`;
                export const getConfigurations = `${Url.base}/Login/SSO/Configurations`;
                export const hasConfiguration = `${Sso.getConfigurations}/Exists`;
            }
        }

        export namespace Notification {
            export const base = `${Url.base}/Notification`;

            export const getUserNotifications = `${Notification.base}`;
            export const seeNotifications = `${Notification.base}/See`;
            export const deleteNotification = `${Notification.base}/{0}`;
            export const deleteSeen = `${Notification.base}`;
        }

        export namespace Provider {
            export const base = `${Url.base}/Provider`;
            export const accounts = `${base}/Account`;
            export const unlink = `${base}/Account/{0}/{1}`;
        }

        export namespace File {
            export const base = `${Url.base}/File`;
            export const upload = `${base}/Upload`;
        }

        export namespace Resource {
            export const base = `${Url.base}/Resource`;
            export const identified = `${base}/{0:guid}`;

            export const checkFileSize = `${base}/CheckFileSize`
            export const find = `${identified}`;
            export const childrenPaginated = `${identified}/Children?PageOffset={1}&PageSize={2}&sort={3}`;
            export const unlock = `${identified}/CheckIn`;
            export const multiLock = `${base}/CheckOutFiles`;
            export const multiUnlock = `${base}/CheckInFiles`;
            export const completeChildren = `${base}/Children/Complete`;
            export const completeChildrenWithoutIdentities = `${base}/Children/Complete/WithoutIdentities`;
            export const favorite = `${identified}/Favorite?search={1}`;
            export const userFavorite = `${base}/Favorite?search={0}`;
            export const setFavorite = `${identified}/Favorite`;
            export const path = `${identified}/Path`;
            export const fullpath = `${identified}/Path/Full`;
            export const multiplePath = `${base}/Path`;
            export const resourceType = `${base}/ResourceType/{0}`;
            export const pathWithSpecificRoot = `${identified}/Path/Root/{1}`;
            export const moveMultiple = `${base}/MoveTo/{0}`;
            export const copyMultiple = `${base}/CopyTo/{0}`;
            export const binRes = `${identified}/BinRes`;
            export const empty = `${identified}/Empty`;
            export const deleteMultiple = `${base}/Delete`;
            export const recycleMultiple = `${base}/Recycle`;
            export const restoreMultiple = `${base}/Restore`;
            export const viewerSupport = `${base}/ViewerSupport`;
            export const getAvailableSpace = `${identified}/AvailableSpace`;
            export const getCompanyCheckedOut = `${base}/CheckedOut/Company/{0:guid}`;
            export const getUserCheckedOut = `${base}/CheckedOut/User/{0:guid}`;
            export const getResourceHistory = `${identified}/Activity/{1:number}`;
            export const downloadForViewer = `${identified}/Viewer`;
            export const doconutViewerToken = `${identified}/Viewer/Doconut?subIndex={1:number}`;
            export const doconutMFilesViewerToken = `${base}/Viewer/Doconut/MFiles/{0:guid}/{1:guid}/{2:string}/{3:number}?subIndex={4:number}`;
            export const doconutViewerClose = `${base}/Viewer/Doconut/Close?token={0:string}`;
            export const indexResources = `${identified}/Index`;
            export const reindexResources = `${identified}/Reindex`;
            export const getGalleryWidgetContent = `${identified}/ContentGalleryWidget/{1:guid}`;
            export const childrenGalleryWidgetContent = `${identified}/ChildrenGalleryWidget/{1:guid}`;
            export const zip = `${Resource.base}/Zip`;
            export const zipDownload = `${Resource.base}/Zip/Download/{0:guid}`;

            export namespace Attribute {
                export const base = `${Resource.identified}/Attribute`;

                export const update = `${base}`;
                export const remove = `${base}/{1}`;
            }

            export namespace Comment {
                export const base = `${Resource.identified}/Topic`;

                export const getTopic = `${base}/{1}`;
                export const getTopicFromComment = `${base}/Comment/{1}`;
                export const getAllTopic = `${base}`;
                export const getComments = `${base}/{1:string}/Comments`;
                export const getReplies = `${base}/{1:string}/Comment/{2:string}/Replies`;
                export const createTopic = `${base}`;
                export const createFileComment = `${base}/Comment`;
                export const createFolderComment = `${base}/{1}/Comment`;
                export const createReply = `${base}/{1}/Comment/{2}`;
                export const updateTopic = `${base}/{1}`;
                export const updateComment = `${base}/{1}/Comment/{2}`;
                export const commentSeen = `${base}/{1}/Comments/Seen`;
                export const deleteTopic = `${base}/{1}`;
                export const deleteComment = `${base}/{1}/Comment/{2}`;
            }

            export namespace Eds {
                export const base = `${Resource.base}/Eds`;
                export const identified = `${Resource.identified}/Eds`;

                export const requestSignature = `${base}/Request`;
                export const revokeRequest = `${identified}/Revoke`;
                export const status = `${identified}/Status`;
            }

            export namespace Share {
                export const base = `${Resource.identified}/Share`;

                export const getResourceShareLink = `${Share.base}/Link`;
                export const getResourcePublicShare = `${Share.base}/Public`;
                export const getIdentities = `${Share.base}/Identities`;
                export const createShares = `${Share.base}`;
                export const createShareWithLink = `${Share.base}/Link`;
                export const unsubscribeMultiple = `${Resource.base}/Share/Unsubscription`;
                export const subscribeMultiple = `${Resource.base}/Share/Subscription`;
                export const storeSharePassword = `${Share.base}/Password`;
                export const updateShares = `${Share.base}`;
                export const updateShare = `${Share.base}/{1:guid}`;
                export const removeShare = `${Share.base}/{1:guid}`;
            }

            export namespace Right {
                export const base = `${Resource.identified}/Right`;

                export const update = `${base}`;
            }

            export namespace Version {
                export const base = `${Resource.identified}/Version`;

                export const getAll = `${Version.base}`;
                export const getVersion = `${Version.base}/{1}`;
                export const update = `${Version.base}`;
                export const changeOfficial = `${Version.base}/{1}/Official`;
                export const remove = `${Version.base}/{1}/Delete`;
                export const removeMultiple = `${Version.base}/Delete`;
                export const copyAsNewDocument = `${Version.base}/{1}/CopyTo/{2:guid}`;
            }
        }

        export namespace Room {
            export const base = `${Url.base}/Room`;
            export const identified = `${Room.base}/{0:guid}`;

            export const path = `${Room.base}/Path/{0:guid}`
            export const createdHubs = `${Room.base}/CreatedHubs`;
            export const getHub = `${Room.identified}`;
            export const getPublicHub = `${Room.identified}/Public`;
            export const getUniqueRootHub = `${Room.base}/UniqueRootRoom`;
            export const getPublicHubByShortcut = `${Room.base}/shortcut/{0:string}/Public`;
            export const getUserHubs = `${Room.identified}/UserHubs`
            export const getHubRelatedHubs = `${Room.identified}/RelatedHubs`
            export const myHubs = `${Room.base}/MyHubs`;
            export const getForResourceSelector = `${Room.base}/ResourceSelector/{0}?Flags={1}&PageSize={2:number}&From={3}&Search={4}&OwnershipFilter={5}`;
            export const getLastthreeFavoriteAndUpdateHub = `${Room.base}/MyLastHubs`;
            export const searchLastthreeFavoriteAndUpdateHub = `${Room.base}/SearchMyLastHubs?q={0}`;
            export const checkLimit = `${Room.base}/CheckLimit`;
            export const checkIsAvailableShortcut = `${Room.identified}/Shortcut/{1:string}`;
            export const create = `${Room.base}`;
            export const setFavorite = `${Room.identified}/Favorite`;
            export const updateStatus = `${Room.identified}/Status`;
            export const updateKind = `${Room.identified}/Kind`;
            export const move = `${Room.base}/Move`;
            export const update = `${Room.identified}`;
            export const groups = `${Room.identified}/Groups`;
            export const updateLanguages = `${Room.identified}/Languages`;
            export const updateCompanyLogo = `${Room.identified}/CompanyLogo`;
            export const updateBackground = `${Room.identified}/Background`;
            export const updateBackgroundLogin = `${Room.identified}/BackgroundLogin`;

            export const remove = `${Room.identified}`;
            export const removeNeutralLanguages = `${Room.identified}/NeutralLanguages`;

            export const parents = `${Room.identified}/Parents`;
            export const getMessage = `${Room.identified}/Message`;
            export const updateMessage = `${Room.base}/Message`;
            export const deleteMessage = `${Room.base}/Message/Delete`;

            export namespace User {
                export const base = `${Room.identified}/Users`;

                export const identities = `${User.base}/All`;
                export const fullIdentities = `${User.base}/AllWithGroupMembers`;
                export const getUserMetasFromHub = `${User.base}/Current/UserMetas`;
                export const getHubGroupMembers = `${User.base}/GroupMembers/{1:guid}`;
                export const getCompanyUser = `${User.base}/{1:guid}/CompanyUser`;
                export const updateAdmin = `${User.base}/{1:guid}/Administrator`;
                export const updateUserRole = `${User.base}/{1:guid}/Role`;
                export const remove = `${User.base}/{1:guid}`;
                export const getHubCurrentUser = `${User.base}/Current`;
                export const getUserProfile = `${User.base}/{1:guid}/Profile`;
            }

            export namespace CustomField {
                export const base = `${Room.identified}/CustomFields`;

                export const get = `${CustomField.base}`;
                export const getAll = `${CustomField.base}/All`;
                export const getAllFromCompany = `${CustomField.base}/Company`;
                export const getDependencies = `${CustomField.base}/{1:guid}/Dependencies`;

                export const create = `${CustomField.base}`;
                export const update = `${CustomField.base}`;
                export const remove = `${CustomField.base}/{1:guid}`;
                export const forceRemove = `${CustomField.base}/{1:guid}/Force`;
            }

            export namespace Modules {
                export const base = `${Room.identified}/Modules`;

                export const getModules = `${Modules.base}`;
                export const getModulesRights = `${Modules.base}/Rights`;
                export const getHubCurrentUserEffectiveRights = `${Modules.base}/Rights/Current/Effective`;
                export const getHubModulesEffectiveRights = `${Modules.base}/Rights/Effective`;
                export const getHubUserModulesRights = `${Modules.base}/Rights/{1:guid}`;
                export const getHubUserModulesInheritedRights = `${Modules.base}/Rights/{1:guid}/Inherited`;
                export const updateModules = `${Modules.base}`;
                export const updateModulesDescriptions = `${Modules.base}/Descriptions`;
                export const updateModulesRights = `${Modules.base}/Rights`;
            }

            export namespace CustomTask {
                export const base = `${Room.identified}/CustomTask`;
                export const createTask = `${CustomTask.base}`;
                export const updateTask = `${CustomTask.base}`;
                export const updateTasks = `${CustomTask.base}/All`;
                export const getAllTasks = `${CustomTask.base}/All`;
                export const getAllWidgetTasksOverview = `${CustomTask.base}/All/Widget`;
                export const getAllTasksBoundToTarget = `${CustomTask.base}/All/{1:guid}`;
                export const getAllTasksBoundToProject = `${CustomTask.base}/Project/{1:guid}`;
                export const deleteTask = `${CustomTask.base}/{1:guid}`;
            }

            export namespace Project {
                export const base = `${Room.identified}/Modules/Project`;

                export const getProject = `${Project.base}/{1:guid}`;
                export const getProjectViewMode = `${getProject}/View/{2}`;
                export const getDefaultProject = `${Project.base}/Default`;
                export const getTemplateProject = `${Project.base}/Template`;
                export const getVisibleProject = `${Project.base}/Visible`;
                export const createProject = `${Project.base}/Create`;
                export const importProjectWithTask = `${Project.base}/Import/{1:guid}`;

                export const updateProject = `${Project.base}/Update`;
                export const archiveProject = `${Project.base}/Archive`;
                export const deleteProject = `${Project.base}/{1:guid}/Delete`;


                export const getTask = `${Project.base}/{1:guid}/Task/{2:guid}`;
                export const getAllTasks = `${Project.base}/{1:guid}/Task`;
                export const getAllTemplateTasks = `${Project.base}/{1:guid}/Task/Template`;
                export const createTask = `${Project.base}/{1:guid}/Task`;
                export const updateTask = `${Project.base}/{1:guid}/Task/{2:guid}`;
                export const updateTaskDates = `${Project.base}/{1:guid}/Task/{2:guid}/Date`;
                export const updateTaskProgress = `${Project.base}/{1:guid}/Task/{2:guid}/Progression`;
                export const deleteTask = `${Project.base}/{1:guid}/Task/{2:guid}`;

                export const createGroup = `${Project.base}/{1:guid}/TaskGroup`;
                export const getAllGroups = `${Project.base}/{1:guid}/TaskGroup`;
                export const getGroup = `${Project.base}/{1:guid}/TaskGroup/{2:guid}`;
                export const updateGroup = `${Project.base}/{1:guid}/TaskGroup/{2:guid}`;
                export const deleteGroup = `${Project.base}/{1:guid}/TaskGroup/{2:guid}`;

                export const createTag = `${Project.base}/{1:guid}/Tag`;
                export const getAllTags = `${Project.base}/{1:guid}/Tag`;
                export const getTag = `${Project.base}/{1:guid}/Tag/{2:guid}`;
                export const updateTag = `${Project.base}/{1:guid}/Tag/{2:guid}`;
                export const deleteTag = `${Project.base}/{1:guid}/Tag/{2:guid}`;

                export const createTagGroup = `${Project.base}/{1:guid}/TagGroup`;
                export const getAllTagGroups = `${Project.base}/{1:guid}/TagGroup`;
                export const getTagGroup = `${Project.base}/{1:guid}/TagGroup/{2:guid}`;
                export const updateTagGroup = `${Project.base}/{1:guid}/TagGroup/{2:guid}`;
                export const deleteTagGroup = `${Project.base}/{1:guid}/TagGroup/{2:guid}`;

                export const createBoard = `${Project.base}/{1:guid}/Board`;
                export const getAllBoards = `${Project.base}/{1:guid}/Board`;
                export const getBoard = `${Project.base}/{1:guid}/Board/{2:guid}`;
                export const updateBoard = `${Project.base}/{1:guid}/Board/{2:guid}`;
                export const deleteBoard = `${Project.base}/{1:guid}/Board/{2:guid}`;

                export const roomProjectRights = `${Project.base}/Rights`;
                export const projectRights = `${Project.base}/{1:guid}/Rights`;
                export const roomProjectRightsEffective = `${Project.base}/Rights/Effective`;
                export const projectRightsEffective = `${Project.base}/{1:guid}/Rights/Effective`;

                export const createOrEditProjectEmailReminder = `${Project.base}/AddOrEditEmailReminder`;
                export const getprojectTaskEmailReminders = `${Project.base}/{1:guid}/GetEmailReminders`;
                export const deleteProjectTaskEmailReminder = `${Project.base}/{1:guid}/DeleteEmailReminder`;

                export namespace TaskKind {
                    export const base = `${Project.base}/{1:guid}/Task/Kind`;

                    export const create = TaskKind.base;
                    export const all = TaskKind.base;
                    export const find = `${TaskKind.base}/{2:guid}`;
                    export const image = `${TaskKind.base}/{2:guid}/Image`;
                    export const update = `${TaskKind.base}/{2:guid}`;
                    export const updateImage = `${TaskKind.base}/{2:guid}/Image`;
                    export const remove = `${TaskKind.base}/{2:guid}`;
                }
            }

            export namespace Dashboard {
                export const base = `${Room.identified}/Modules/Dashboard`;

                export const getAllPage = `${Dashboard.base}/Pages`;
                export const getActivePages = `${Dashboard.base}/ActivePages`
                export const savePage = `${Dashboard.base}/CreateOrUpdatePage`;
                export const savePages = `${Dashboard.base}/CreateOrUpdatePages`;
                export const deletePage = `${Dashboard.base}/DeletePage`;
                export const updatePageImage = `${Dashboard.base}/{1:guid}/Image`;
                export const getNotModulePages = `${Dashboard.base}/GetNotModulePages`;
            }

            export namespace Activities {
                export const base = `${Room.base}/Activities`;
                export const identified = `${Room.identified}/Activities`;

                export const getGeneral = `${Activities.identified}/GeneralActivities`;
                export const getMFilesActivities = `${Activities.base}/MFilesActivities`;
            }

            export namespace Resource {
                export const base = `${Room.base}/{0:guid}/Resource`;

                export const getCheckedOutFiles = `${base}/CheckedOut`;
                export const getResource = `${base}/{1:guid}`;
            }

            export namespace Widget {
                export const base = `${Room.identified}/Widget`;

                export const create = `${Widget.base}/Create/{1:guid}`;
                export const getActive = `${Widget.base}/Active/{1:guid}/Page`;
                export const getUsedKind = `${Widget.base}/Used/Kind`;
                export const getNewUniqueName = `${Widget.base}/NewUniqueName`;
                export const getAllWidgets = `${Widget.base}/AllWidgets?search={1}`;
                export const get = `${Widget.base}/{1:guid}`;
                export const getBlogActivityData = `${Widget.base}/{1:guid}/BlogActivity`;
                export const getFileActivityData = `${Widget.base}/{1:guid}/FileActivity`;
                export const getUsefulLinksData = `${Widget.base}/{1:guid}/UsefulLinks`;
                export const getCustomContentData = `${Widget.base}/{1:guid}/CustomContent`;
                export const getProjectData = `${Widget.base}/{1:guid}/Project`;
                export const getCalendarData = `${Widget.base}/{1:guid}/Calendar`;
                export const getBarLineData = `${Widget.base}/{1:guid}/Data/Chart/BarLine`;
                export const getPieData = `${Widget.base}/{1:guid}/Data/Chart/Pie`;
                export const getFormChart = `${Widget.base}/{1:guid}/Chart/Form`;
                export const getFormChartPieView = `${Widget.base}/{1:guid}/Data/Chart/Form/Pie/View`;
                export const getFormChartBarView = `${Widget.base}/{1:guid}/Data/Chart/Form/Bar/View`;
                export const setFormChart = `${Widget.base}/{1:guid}/Chart/Form`;
                export const getAcl = `${Widget.base}/Acl`;
                export const getAclForWidget = `${Widget.base}/{1:guid}/Acl`;
                export const update = `${Widget.base}/Update`;
                export const updateOrder = `${Widget.base}/UpdateWidgetsOrder`;
                export const updateSize = `${Widget.base}/UpdateWidgetSize`;
                export const updateAcl = `${Widget.base}/Acl`;
                export const remove = `${Widget.base}/{1:guid}`;
                export const getCustomContent = `${Widget.base}/{1:guid}/CustomContent/DataForUpdate`;
                export const setCustomContent = `${Widget.base}/{1:guid}/CustomContent/Data`;
                export const customContentUrl = `${Widget.base}/{1:guid}/CustomContent/Data`;
                export const duplicate = `${Widget.base}/{1:guid}/Duplicate`;

                export namespace Chart {
                    export namespace Pie {
                        export const getForUpdate = `${Widget.base}/{1:guid}/DataForUpdate/Chart/Pie`;
                        export const update = `${Widget.base}/{1:guid}/Chart/Pie`;
                        export const updateCollection = `${Widget.base}/{1:guid}/Chart/Pie/Collection`;
                    }

                    export namespace BarLine {
                        export const getForUpdate = `${Widget.base}/{1:guid}/DataForUpdate/Chart/BarLine`;
                        export const update = `${Widget.base}/{1:guid}/Chart/BarLine`;
                        export const updateCollection = `${Widget.base}/{1:guid}/Chart/BarLine/Collection`;
                    }
                }

                export namespace People {
                    export const base = `${Widget.base}/{1:guid}/People`;
                }

                export const getBoundExtension = `${Widget.base}/{1:guid}/Extension`;
                export const extensionWidgetConfig = `${Widget.base}/{1:guid}/Extension/Configuration`;
                export const extensionWidgetConfigCreation = `${Widget.extensionWidgetConfig}/{2:string}/{3:number}/{4:guid}`;
                export const extensionWidgetConfigCreationWithSubMode = `${Widget.extensionWidgetConfigCreation}/{5:number}`;
                export const extensionWidgetDisplay = `${Widget.base}/{1:guid}/Extension/{2:string}/Display/{3:number}`;
                export const mfilesSingleFileDownload = `${Widget.base}/{1:guid}/MFiles/{2}/{3}/{4}`;

                export const mfilesFetchFilesContentToDownload = `${Widget.base}/{1:guid}/MFiles/FetchFilesContentToDownload`;
                export const mfilesFetchDocumentCollectionMembersContentToDownload = `${Widget.base}/{1:guid}/MFiles/FetchDocumentCollectionMembersContentToDownload/{2:string}`;
                export const mfilesFetchViewContentToDownload = `${Widget.base}/{1:guid}/MFiles/FetchViewContentToDownload`;
                export const mfilesFetchFileIndexContentToDownload = `${Widget.base}/{1:guid}/MFiles/FetchFileIndexContentToDownload`;

                export const mfilesAnnotations = `${Widget.base}/{1:guid}/MFiles/Annotation/{2:string}/{3:number}/{4:number}`;
                export const mfilesCurrentUserAnnotations = `${Widget.base}/{1:guid}/MFiles/Annotation/{2:string}/{3:number}/{4:number}/CurrentUser`;
                export const mfilesDownloadAnnotationContent = `${Widget.base}/{1:guid}/MFiles/Annotation/{2:string}/Content`;

                export namespace UsefulLink {
                    export const base = `${Widget.base}/{1:guid}/UsefulLink`;

                    export const create = UsefulLink.base;
                    export const createMultiple = `${UsefulLink.base}/Multiple`;
                    export const find = `${UsefulLink.base}/{2:guid}`;
                    export const update = `${UsefulLink.base}/{2:guid}`;
                    export const setOrder = `${UsefulLink.base}/Order`;
                    export const updatePicture = `${Widget.base}/UsefulLink/{1:guid}/Icon/{2:number}`;
                    export const updatePictureLcid = `${UsefulLink.base}/{2:guid}/Icon/Change/{3:number}/{4:number}`;
                    export const remove = `${UsefulLink.base}/{2:guid}`;
                }

                export namespace Gallery {
                    export const base = `${Widget.base}/{1:guid}`;
                    export const getGallery = `${Gallery.base}/Gallery`;
                    export const updateGallery = `${Gallery.base}/Gallery`;
                }

                export namespace Form {
                    export const base = `${Widget.base}/{1:guid}`;
                    export const getFormResponseFromTopic = `${Widget.base}/TopicFormResponse/{1:guid}`;
                    export const getFormWidget = `${Form.base}/FormWidget`;
                    export const getForms = `${Form.base}/Forms`;
                    export const getFormTopics = `${Form.base}/FormResponseTopic/{2:guid}/{3:guid}`;
                    export const createForm = `${Form.base}/Form`;
                    export const createFormResponse = `${Form.base}/FormWidgetResponse`;
                    export const archiveFormResponse = `${Form.base}/FormWidgetArchiveResponse`;
                    export const deleteFormResponse = `${Form.base}/FormWidgetResponse/{2:guid}`;
                    export const createAndUpdateFormResponse = `${Form.base}/RelativeFormWidgetResponse`;
                    export const getRelativeFormResponse = `${Form.base}/GetRelativeFormWidgetResponse`;
                    export const updateFormResponseContentValue = `${Form.base}/UpdateFormWidgetContentValue/{2:guid}/{3:guid}`;
                    export const getFormCondition = `${Form.base}/GetFormCondition/{2:guid}`;
                    export const setFormCondition = `${Form.base}/SetFormCondition/{2:guid}`;
                }

                export namespace Resource {
                    export const base = `${Widget.base}/{1:guid}/Resource`;
                    export const explorer = `${Widget.base}/{1:guid}/Resource/Explorer?search={2}`;
                }

                export namespace ToDoList {
                    export const base = `${Widget.base}/{1:guid}`;
                    export const getToDoList = `${ToDoList.base}/ToDoList`;
                    export const updateToDoList = `${ToDoList.base}/ToDoList`;
                }
            }

            export namespace Calendar {
                export const base = `${Room.identified}/Modules/Calendar`;

                export const create = base;
                export const all = base;
                export const find = `${base}/{1}`;
                export const getWidgetData = `${base}/Widget`;
                export const update = `${base}/{1}`;
                export const remove = `${base}/{1}`;

                export namespace Event {
                    export const base = `${Calendar.base}/{1}/Event`;

                    export const create = base;
                    export const all = base;
                    export const find = `${base}/{2}`;
                    export const update = `${base}/{2}`;
                    export const remove = `${base}/{2}`;
                }

                export namespace EventKind {
                    export const base = `${Calendar.base}/EventKind`;

                    export const create = base;
                    export const all = base;
                    export const find = `${base}/{1}`;
                    export const update = `${base}/{1}`;
                    export const updateImage = `${base}/{1}/Image`;
                    export const remove = `${base}/{1}`;
                }
            }

            export namespace Team {
                export const base = `${Room.identified}/People`;

                export const getPeoples = `${Team.base}`;
                export const addMembers = `${Team.base}/{1}/Members`;
                export const addGroup = `${Team.base}/{1}`;
                export const updateGroup = `${Team.base}/{1}`;
                export const deleteGroup = `${Team.base}/{1}`;
                export const deleteMember = `${Team.base}/{1}/Member/{2}`;
                export const isLinkedToWidget = `${Team.base}/{1}/WidgetUse`;
            }

            export namespace Template {
                export const base = `${Room.base}/Template`;

                export const createFromTemplate = `${Template.base}`;
                export const editTemplates = `${Template.base}/Edit`;
                export const creationState = `${Template.base}/CreationState/{0}`;
                export const nameAvailability = `${Template.base}/{0}/VerifyName`;
                export const rights = `${Template.base}/{0:guid}/Rights`;
                export const deleteRights = `${Template.base}/{0:guid}/Rights/{1:guid}`;
                export const hubLinks = `${Template.base}/{0:guid}/HubLinks`;
            }

            export namespace Search {
                export const base = `${Room.identified}/Search`;
                export const searchInProject = `${Search.base}/Project`;
                export const searchInUser = `${Search.base}/User`;
                export const searchInEvent = `${Search.base}/Event`;
                export const searchInHub = `${Search.base}/Hub`;
                export const searchInFile = `${Search.base}/File`;
            }

            export namespace PublicRegister {
                export const base = `${Room.identified}/PublicRegister`;
                export const getPendingUsers = `${PublicRegister.base}/PendingUsers`;
                export const createPendingUser = `${PublicRegister.base}/CreatePendingUsers`;
                export const updatePendingUser = `${PublicRegister.base}/UpdatePendingUser`;
                export const getAdvancedConfiguration = `${PublicRegister.base}/GetAdvancedConfiguration`;
                export const updateAdvancedConfiguration = `${PublicRegister.base}/UpdateAdvancedConfiguration`;
            }

            export namespace Social {
                export const base = `${Room.identified}/Social`;
                export const getSubHubs = `${base}/SubHubs`;
                export const getFeed = `${base}/Feed/{1:number}`;
                export const getFeedUnreadElements = `${base}/Feed/Unread`;
                export const getFilteredTopic = `${base}/Topic/Filtered`;
                export const getComments = `${base}/Topic/{1:string}/Comments/Offset/{2:number}`;
                export const getReplies = `${base}/Topic/{1:string}/Comment/{2:string}/Replies/Offset/{3:number}`;
                export const markAsSeen = `${base}/MarkAsSeen`;

                export const getProjectTask = `${base}/ProjectTask/{1:guid}`;
                export const getCalendarEvent = `${base}/CalendarEvent/{1:guid}`;
                export const getResource = `${base}/Resource/{1:guid}`;

                export const getPinnedTopic = `${base}/Topic/Meta/Pinned`;
                export const createTopicMeta = `${base}/Topic/{1:guid}/Meta`;
                export const deleteTopicMeta = `${base}/Topic/{1:guid}/Meta/{2:guid}`;
                export const createCommentMeta = `${base}/Topic/{1:guid}/Comment/{2:guid}/Meta`;
                export const deleteCommentMeta = `${base}/Topic/{1:guid}/Comment/{2:guid}/Meta/{3:guid}`;

                export namespace Thread {
                    export const base = `${Social.base}/Thread`;

                    export const getThreads = `${Thread.base}`;
                    export const getIdentities = `${Thread.base}/{1:guid}/Identities`;
                    export const createThread = `${Thread.base}`;
                    export const updateThread = `${Thread.base}/{1:guid}`;
                    export const markAsSeen = `${Thread.base}/{1:guid}/MarkAsSeen`;
                    export const deleteThread = `${Thread.base}/{1:guid}`;
                    export const getRights = `${Thread.base}/{1:guid}/Rights`;
                    export const setRights = `${Thread.base}/{1:guid}/Rights`;
                }
            }

            export namespace Blog {
                export namespace Rights {
                    const base = `${Room.identified}/Blog/Rights`;

                    export const getAll = `${base}`;
                    export const getCurrentUserRights = `${base}/Current`;
                    export const update = `${base}`;
                }
            }

            export namespace Export {
                const base = `${Room.base}/Export`;
                const identified = `${base}/{0:guid}`;

                export const roomInfo = `${identified}/Info`;
                export const roomExport = `${base}`;
                export const download = `${identified}/Download`;
            }

            export namespace Import {
                const base = `${Room.base}/Import`;

                export const roomImport = `${base}`;
                export const userCanUpdateAtLeastOneHub = `${base}/CanUpdate`;
                export const prepare = `${base}/Prepare`;
            }
        }

        export namespace Server {
            export const base = `${Url.base}/Server`;
            export const webpageCustomization = `${base}/WebpageCustomization/{0}`;
            export const webpageCustomizationWithPath = `${base}/WebpageCustomization/{0}/{1}`;
            export const systemEmail = `${Server.base}/SystemEmail`;
            export const systemAdminLimitations = `${base}/SystemAdminLimitations`;
            export const serverInfo = `${base}/Info`;

            export namespace License {
                export const base = `${Server.base}/License`;

                export const current = `${base}/Current`;
                export const read = `${base}/Read`;
                export const update = `${base}/Update`;
            }
        }

        export namespace Session {
            export const base = `${Url.base}/Session`;
            export const anonymous = `${base}/Anonymous`;
            export const room = `${base}/{0}`;    // Please do not type this url parameter.
        }

        export namespace Security {
            export const base = `${Url.base}/Security`;
            export const companySecurityTemplates = `${base}/Template/{0:guid}`;
        }

        export namespace Settings {
            export const base = `${Url.base}/Settings`;

            export const userSettings = `${base}/GetUserSettings`;
            export const internalUsers = `${base}/InternalUsers`;
            export const internalUser = `${base}/InternalUser/{0}`;
            export const updateInternalUser = `${base}/InternalUser`;
            export const enableInternalUsers = `${base}/InternalUser/Enable`;
            export const disableInternalUsers = `${base}/InternalUser/Disable`;
            export const importInternalUser = `${base}/InternalUser/ImportInternal`;
            export const importExternalUser = `${base}/InternalUser/ImportExternal`;
            export const importInternalUserModel = `${base}/InternalUser/ImportModelInternal`;
            export const importExternalUserModel = `${base}/InternalUser/ImportModelExternal`;

            export const externalUsers = `${base}/ExternalUsers`;
            export const updateExternalUser = `${base}/ExternalUser`;
            export const enableExternalUsers = `${base}/ExternalUser/Enable`;
            export const disableExternalUsers = `${base}/ExternalUser/Disable`;
            export const convertExternalUser = `${base}/ExternalUser/Convert`;

            export const group = `${base}/Group`;
            export const hubGroup = `${base}/HubGroup/{0:guid}`;
            export const withoutHubGroup = `${base}/WithoutHubGroups`;
            export const getGroupMembers = `${group}/{0:guid}`;
            export const getGroupMembersForHubGroup = `${group}/{0:guid}/Room/{1:guid}`;
            export const createGroup = `${group}`;
            export const createHubGroup = `${group}/Room/{0:guid}`;
            export const updateGroup = `${group}/{0:guid}`;
            export const deleteGroup = `${group}/{0:guid}`;

            export const security = `${base}/Security`;
            export const securityDetails = `${security}/{0}`;
            export const createSecurity = `${security}`;
            export const updateSecurity = `${security}/{0}`;
            export const deleteSecurity = `${security}/{0}`;

            export const company = `${base}/Company`;
            export const getCompanySettings = `${company}`;
            export const setCompanySettings = `${company}`;
            export const getOutlookPluginSettings = `${company}/Outlook`;
            export const setOutlookPluginSettings = `${company}/Outlook`;

            export const download = `${base}/Download`;
            export const downloadPlugin = `${base}/Download/{0:string}`;

            export const extensions = `${base}/Extensions`;

            export const resetClientAutoAllow = `${base}/ResetClientAutoAllow`;
            export const getFolderList = `${base}/GetSettingsFolderList`;
            export const updateHomeFolder = `${base}/SetHomeFolder`;

            export namespace Ownership {
                export const base = `${Settings.base}/Ownership`;
                export const transferCompany = `${base}/Company`;
                export const transferHub = `${base}/Hub`;
                export const transferUserResources = `${base}/UserResources`;
            }
        }

        export namespace User {
            export const base = `${Url.base}/User`;

            export const specific = `${User.base}/{0:guid}`;
            export const find = `${User.base}/{0:guid}`;
            export const update = `${User.base}/{0:guid}`;
            export const removeRedirectionEmail = `${User.base}/{0:guid}/RemoveRedirectionEmail`;
            export const updatePicture = `${User.base}/{0:guid}/Picture`;
            export const updatePassword = `${User.base}/UpdatePassword`;
            export const create = `${User.base}`;
            export const createMultiple = `${User.base}/CreateUsers`;
            export const updateFromRoom = `${User.base}/UpdateFromRoom`;
            export const logout = `${User.base}/Logout`;
            export const getCurrent = `${User.base}/Current`;
            export const updateCurrent = `${User.base}/Current`;
            export const getPicture = `${User.base}/{0:guid}/Picture`;
            export const getPictureBase64 = `${User.base}/{0:guid}/Picture/B64`;
            export const getCurrentUserAvailableSpace = `${User.base}/AvailableSpace`;
            export const createSystemAdmin = `${User.base}/SystemAdministrator`;
            export const setUserMetas = `${User.base}/SetUserMetas`;
            export const updateSystemAdmin = `${User.specific}/SystemAdministrator`;
            export const lock = `${User.specific}/Lock`;
            export const unlock = `${User.specific}/Unlock`;
            export const createActivationToken = `${User.specific}/ActivationToken`;

            export namespace Companies {
                export const base = `${Url.base}/User/Company`;

                export const getUserCompanies = `${Companies.base}/All`;
                export const userCompanyAccept = `${Companies.base}/{0:guid}/Accept`;
                export const userCompanyRefuse = `${Companies.base}/{0:guid}/Refuse`;
                export const userCompanyLeave = `${Companies.base}/{0:guid}/Leave`;
            }

            export namespace Role {
                export const base = `${User.specific}/Role`;
                export const unset = `${base}/Unset`;
                export const systemAdmins = `${User.base}/Role/SystemAdministrator`;
            }
        }

        export namespace Watermark {
            export const base = `${Url.base}/Watermark`;

            export const getCompanyWatermarks = `${base}/Company/{0:guid}`;
            export const getRoomWatermarks = `${base}/Room/{0:guid}`;
            export const roomWatermarkConfiguration = `${base}/Room/{0:guid}/Configuration`;
            export const widgetWatermarkConfiguration = `${base}/Widget/{0:guid}/Configuration`;
            export const createOrUpdateRoomWatermarks = `${base}/Room/{0:guid}/Update`;
            export const deleteWatermark = `${base}/{0:guid}/Delete`;
        }

        export namespace Extension {
            export const base = `${Url.base}/Extension`;
            export const widgetExtensions = `${base}/Widget/{0:Guid}`;
            export const availableExtensions = `${base}/Available/{0}/{1:guid}`;
            export const configuration = `${base}/Configuration`;
            export const identifiedConfiguration = `${base}/Configuration/{0:guid}`;
            export const getOrEditNewConfiguration = `${base}/Configuration/{0}/{1:guid}`;
            export const configurations = `${base}/Configurations/{0}/{1}/{2:guid}`;

            export namespace Cache {
                export const base = `${Extension.base}/Cache/{0:guid}`;
                export const structure = `${base}/Structure`;
                export const icons = `${base}/Icons`;
            }

            export namespace IdentityProvider {
                export const base = `${Extension.configuration}/IdentityProvider`;
                export const getSignInUrl = `${base}/SignIn`;
                export const revokeTokens = `${base}/Revoke`;
                export const signInCallback = `${base}/Callback`;
            }

            export const audit = `${base}/Audit`;
            export const auditDetails = `${base}/Audit/Details/{0:guid}/{1:guid}`;

            export namespace MFiles {
                export const base = `${Extension.base}/MFiles`;

                export const refreshClassProperties = `${base}/{0:guid}/{1:guid}/Refresh/Class/{2:number}`;
                export const upload = `${base}/Upload/{0:guid}/{1:guid}`;
                export const preload = `${base}/{0:guid}/{1:guid}/Preload`
                export const uploadCreateObject = `${base}/Upload/{0:guid}/{1:guid}/CreateObject`;
                export const replace = `${base}/Upload/{0:guid}/{1:guid}/ReplaceFile`;
                export const uploadAnnotation = `${base}/Upload/Annotation/{0:guid}/{1:guid}/{2:string}/{3:number}/{4:number}`;
                export const updateProperties = `${base}/UpdateProperties/{0:guid}/{1:guid}/{2:string}/{3:string}`;
                export const valueListItemsConfiguration = `${base}/ValueList/{0:guid}/{1:guid}/{2:number}/Configuration`;
                export const valueListItemsFilterable = `${base}/ValueList/{0:guid}/{1:guid}/{2:number}/Filterable`;
                export const valueListItemsDisplay = `${base}/ValueList/{0:guid}/{1:guid}/{2}/Display`;
                export const explorerUploadConfiguration = `${base}/Upload/Configuration/{0:guid}/{1:guid}`;
                export const viewUploadPrefill = `${base}/Upload/Prefill/{0:guid}/{1:guid}`;
                export const history = `${base}/History/{0:guid}/{1:guid}/{2:number}/{3:number}`;
                export const objectProperties = `${base}/ObjectProperties/{0:guid}/{1:guid}/{2:string}`;
                export const openInOfficeOnline = `${base}/OfficeOnline/{0:guid}/{1:guid}/{2:string}/{3:number}/{4:boolean}`;
                export const checkOut = `${base}/CheckOut/{0:guid}/{1:guid}/{2:string}`;
                export const checkIn = `${base}/CheckIn/{0:guid}/{1:guid}/{2:string}`;
                export const undoCheckOut = `${base}/UndoCheckOut/{0:guid}/{1:guid}/{2:string}`;
                export const download = `${base}/Download/{0:guid}`;
            }
        }


        export namespace Office {
            export const base = `${Url.base}/Office`;

            export const viewer = `${base}/Viewer/{0:string}`
        }
    }
}